import { Injectable } from '@angular/core';
import { IDataProvider } from '@x/common/data';
import { Observable } from 'rxjs';
import { ISageItemSyncDetail } from '../sage-types';
import { SageItemSyncClient } from '../services/sage-item-sync.client';

@Injectable({ providedIn: 'root' })
export class SageItemSyncDetailProvider implements IDataProvider<ISageItemSyncDetail, string> {
  constructor(private client: SageItemSyncClient) {}

  fetchSingle(id: string): ISageItemSyncDetail | Observable<ISageItemSyncDetail> {
    return this.client.fetchDetail(id);
  }

  toId(model: ISageItemSyncDetail): string {
    return model.sku;
  }

  toString(model: ISageItemSyncDetail): string {
    return `${model.sku}`;
  }
}
