import * as Types from '@x/schemas/sage';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type SageItemSyncDetailFragment = { __typename: 'SageItemSyncObject', id: number, sku: string, name?: string | null | undefined, description?: string | null | undefined, category?: string | null | undefined, enabled: boolean, lastSyncedAt?: any | null | undefined };

export type SageItemSyncQueryVariables = Types.Exact<{
  sku: Types.Scalars['String']['input'];
}>;


export type SageItemSyncQuery = { __typename: 'Query', sageItemSync: { __typename: 'SageItemSyncObject', id: number, sku: string, name?: string | null | undefined, description?: string | null | undefined, category?: string | null | undefined, enabled: boolean, lastSyncedAt?: any | null | undefined } };

export type UpdateSageItemSyncMutationVariables = Types.Exact<{
  input: Types.UpdateSageStockItemSyncInput;
}>;


export type UpdateSageItemSyncMutation = { __typename: 'Mutation', updateSageItemSync: { __typename: 'SageItemSyncObject', id: number, sku: string, name?: string | null | undefined, description?: string | null | undefined, category?: string | null | undefined, enabled: boolean, lastSyncedAt?: any | null | undefined } };

export const SageItemSyncDetailFragmentDoc = gql`
    fragment SageItemSyncDetail on SageItemSyncObject {
  id
  sku
  name
  description
  category
  enabled
  lastSyncedAt
}
    `;
export const SageItemSyncDocument = gql`
    query SageItemSync($sku: String!) {
  sageItemSync(sku: $sku) {
    ...SageItemSyncDetail
  }
}
    ${SageItemSyncDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SageItemSyncGQL extends Apollo.Query<SageItemSyncQuery, SageItemSyncQueryVariables> {
    document = SageItemSyncDocument;
    client = 'sage';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateSageItemSyncDocument = gql`
    mutation UpdateSageItemSync($input: UpdateSageStockItemSyncInput!) {
  updateSageItemSync(input: $input) {
    ...SageItemSyncDetail
  }
}
    ${SageItemSyncDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateSageItemSyncGQL extends Apollo.Mutation<UpdateSageItemSyncMutation, UpdateSageItemSyncMutationVariables> {
    document = UpdateSageItemSyncDocument;
    client = 'sage';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }