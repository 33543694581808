import { Injectable } from '@angular/core';
import { IDataCollection } from '@x/common/data';
import { mapFetchResultData } from '@x/common/graph';
import { UpdateSageOrderSyncInput } from '@x/schemas/sage';
import { Observable } from 'rxjs';
import {
  DeleteSageOrderSyncGQL,
  SageOrderSyncDetailFragment,
  SageOrderSyncGQL,
  SageOrderSyncPayloadGQL,
  SageOrderSyncRowFragment,
  SageOrderSyncRowGQL,
  SageOrderSyncRowsGQL,
  SageOrderSyncRowsQueryVariables,
  SyncSageOrderSyncGQL,
  UpdateSageOrderSyncGQL,
} from './sage-order-sync.gql.generated';

@Injectable()
export class SageOrderSyncClient {
  constructor(
    private readonly SageOrderSyncGQL: SageOrderSyncGQL,
    private readonly UpdateSageOrderSyncGQL: UpdateSageOrderSyncGQL,
    private readonly SyncSageOrderSyncGQL: SyncSageOrderSyncGQL,
    private readonly DeleteSageOrderSyncGQL: DeleteSageOrderSyncGQL,
    private readonly SageOrderSyncRowGQL: SageOrderSyncRowGQL,
    private readonly SageOrderSyncRowsGQL: SageOrderSyncRowsGQL,
    private readonly sageOrderSyncPayloadGQL: SageOrderSyncPayloadGQL,
  ) {}

  fetchDetail(number: string): Observable<SageOrderSyncDetailFragment> {
    return this.SageOrderSyncGQL.fetch({ number }).pipe(mapFetchResultData((r) => r.sageOrderSync));
  }

  fetchRow(number: string): Observable<SageOrderSyncRowFragment> {
    return this.SageOrderSyncRowGQL.fetch({ number }).pipe(
      mapFetchResultData((r) => r.sageOrderSync),
    );
  }

  fetchRows(
    query: SageOrderSyncRowsQueryVariables,
  ): Observable<IDataCollection<SageOrderSyncDetailFragment>> {
    return this.SageOrderSyncRowsGQL.fetch(query).pipe(mapFetchResultData((r) => r));
  }

  update(input: UpdateSageOrderSyncInput) {
    return this.UpdateSageOrderSyncGQL.mutate({ input }).pipe(
      mapFetchResultData((r) => r.updateSageOrderSync),
    );
  }

  sync(number: string) {
    return this.SyncSageOrderSyncGQL.mutate({ number }).pipe(
      mapFetchResultData((r) => r.syncSageOrderSync),
    );
  }

  delete(number: string) {
    return this.DeleteSageOrderSyncGQL.mutate({ number }).pipe(
      mapFetchResultData((r) => r.deleteSageOrderSync),
    );
  }

  fetchPayload(number: string) {
    return this.sageOrderSyncPayloadGQL
      .fetch({ number })
      .pipe(mapFetchResultData((r) => r.sageOrderSyncPayload));
  }
}
