import { SageSyncStatus } from '@x/schemas/sage';

export const SAGE_LANGUAGE = {
  SageSyncStatus: {
    [SageSyncStatus.Unsynced]: 'Unsynced',
    [SageSyncStatus.InSync]: 'In Sync',
    [SageSyncStatus.OutSync]: 'Out Sync',
    [SageSyncStatus.Error]: 'Error',
    [SageSyncStatus.Deleted]: 'Deleted',
    [SageSyncStatus.Uneligible]: 'Uneligible',
  },
};
