import * as Types from '@x/schemas/sage';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type SageOrderSyncDetailFragment = { __typename: 'SageOrderSyncObject', id: string, number: string, status: Types.SageSyncStatus, diff?: any | null | undefined, error?: string | null | undefined, enabled: boolean, delete: boolean, lastSyncedAt?: any | null | undefined, createdAt?: any | null | undefined, updatedAt?: any | null | undefined, deletedAt?: any | null | undefined };

export type SageOrderSyncRowFragment = { __typename: 'SageOrderSyncObject', id: string, number: string, status: Types.SageSyncStatus, diff?: any | null | undefined, error?: string | null | undefined, enabled: boolean, delete: boolean, lastSyncedAt?: any | null | undefined, createdAt?: any | null | undefined, updatedAt?: any | null | undefined, deletedAt?: any | null | undefined };

export type SageOrderSyncQueryVariables = Types.Exact<{
  number: Types.Scalars['String']['input'];
}>;


export type SageOrderSyncQuery = { __typename: 'Query', sageOrderSync: { __typename: 'SageOrderSyncObject', id: string, number: string, status: Types.SageSyncStatus, diff?: any | null | undefined, error?: string | null | undefined, enabled: boolean, delete: boolean, lastSyncedAt?: any | null | undefined, createdAt?: any | null | undefined, updatedAt?: any | null | undefined, deletedAt?: any | null | undefined } };

export type SageOrderSyncRowQueryVariables = Types.Exact<{
  number: Types.Scalars['String']['input'];
}>;


export type SageOrderSyncRowQuery = { __typename: 'Query', sageOrderSync: { __typename: 'SageOrderSyncObject', id: string, number: string, status: Types.SageSyncStatus, diff?: any | null | undefined, error?: string | null | undefined, enabled: boolean, delete: boolean, lastSyncedAt?: any | null | undefined, createdAt?: any | null | undefined, updatedAt?: any | null | undefined, deletedAt?: any | null | undefined } };

export type SageOrderSyncRowsQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.SageOrderSyncFilterInput>;
  page?: Types.InputMaybe<Types.PageInput>;
  sort?: Types.InputMaybe<Types.SortInput>;
}>;


export type SageOrderSyncRowsQuery = { __typename: 'Query', totalItemsCount: number, items: Array<{ __typename: 'SageOrderSyncObject', id: string, number: string, status: Types.SageSyncStatus, diff?: any | null | undefined, error?: string | null | undefined, enabled: boolean, delete: boolean, lastSyncedAt?: any | null | undefined, createdAt?: any | null | undefined, updatedAt?: any | null | undefined, deletedAt?: any | null | undefined }> };

export type SageOrderSyncPayloadQueryVariables = Types.Exact<{
  number: Types.Scalars['String']['input'];
}>;


export type SageOrderSyncPayloadQuery = { __typename: 'Query', sageOrderSyncPayload: any };

export type UpdateSageOrderSyncMutationVariables = Types.Exact<{
  input: Types.UpdateSageOrderSyncInput;
}>;


export type UpdateSageOrderSyncMutation = { __typename: 'Mutation', updateSageOrderSync: { __typename: 'SageOrderSyncObject', id: string, number: string, status: Types.SageSyncStatus, diff?: any | null | undefined, error?: string | null | undefined, enabled: boolean, delete: boolean, lastSyncedAt?: any | null | undefined, createdAt?: any | null | undefined, updatedAt?: any | null | undefined, deletedAt?: any | null | undefined } };

export type SyncSageOrderSyncMutationVariables = Types.Exact<{
  number: Types.Scalars['String']['input'];
}>;


export type SyncSageOrderSyncMutation = { __typename: 'Mutation', syncSageOrderSync: { __typename: 'SageOrderSyncObject', id: string, number: string, status: Types.SageSyncStatus, diff?: any | null | undefined, error?: string | null | undefined, enabled: boolean, delete: boolean, lastSyncedAt?: any | null | undefined, createdAt?: any | null | undefined, updatedAt?: any | null | undefined, deletedAt?: any | null | undefined } };

export type DeleteSageOrderSyncMutationVariables = Types.Exact<{
  number: Types.Scalars['String']['input'];
}>;


export type DeleteSageOrderSyncMutation = { __typename: 'Mutation', deleteSageOrderSync: { __typename: 'SageOrderSyncObject', id: string, number: string, status: Types.SageSyncStatus, diff?: any | null | undefined, error?: string | null | undefined, enabled: boolean, delete: boolean, lastSyncedAt?: any | null | undefined, createdAt?: any | null | undefined, updatedAt?: any | null | undefined, deletedAt?: any | null | undefined } };

export const SageOrderSyncDetailFragmentDoc = gql`
    fragment SageOrderSyncDetail on SageOrderSyncObject {
  id
  number
  status
  diff
  error
  enabled
  delete
  lastSyncedAt
  createdAt
  updatedAt
  deletedAt
}
    `;
export const SageOrderSyncRowFragmentDoc = gql`
    fragment SageOrderSyncRow on SageOrderSyncObject {
  id
  number
  status
  diff
  error
  enabled
  delete
  lastSyncedAt
  createdAt
  updatedAt
  deletedAt
}
    `;
export const SageOrderSyncDocument = gql`
    query SageOrderSync($number: String!) {
  sageOrderSync(number: $number) {
    ...SageOrderSyncDetail
  }
}
    ${SageOrderSyncDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SageOrderSyncGQL extends Apollo.Query<SageOrderSyncQuery, SageOrderSyncQueryVariables> {
    document = SageOrderSyncDocument;
    client = 'sage';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SageOrderSyncRowDocument = gql`
    query SageOrderSyncRow($number: String!) {
  sageOrderSync(number: $number) {
    ...SageOrderSyncRow
  }
}
    ${SageOrderSyncRowFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SageOrderSyncRowGQL extends Apollo.Query<SageOrderSyncRowQuery, SageOrderSyncRowQueryVariables> {
    document = SageOrderSyncRowDocument;
    client = 'sage';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SageOrderSyncRowsDocument = gql`
    query SageOrderSyncRows($filter: SageOrderSyncFilterInput, $page: PageInput, $sort: SortInput) {
  items: sageOrderSyncs(filter: $filter, page: $page, sort: $sort) {
    ...SageOrderSyncRow
  }
  totalItemsCount: sageOrderSyncsCount(filter: $filter)
}
    ${SageOrderSyncRowFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SageOrderSyncRowsGQL extends Apollo.Query<SageOrderSyncRowsQuery, SageOrderSyncRowsQueryVariables> {
    document = SageOrderSyncRowsDocument;
    client = 'sage';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SageOrderSyncPayloadDocument = gql`
    query SageOrderSyncPayload($number: String!) {
  sageOrderSyncPayload(number: $number)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SageOrderSyncPayloadGQL extends Apollo.Query<SageOrderSyncPayloadQuery, SageOrderSyncPayloadQueryVariables> {
    document = SageOrderSyncPayloadDocument;
    client = 'sage';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateSageOrderSyncDocument = gql`
    mutation UpdateSageOrderSync($input: UpdateSageOrderSyncInput!) {
  updateSageOrderSync(input: $input) {
    ...SageOrderSyncDetail
  }
}
    ${SageOrderSyncDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateSageOrderSyncGQL extends Apollo.Mutation<UpdateSageOrderSyncMutation, UpdateSageOrderSyncMutationVariables> {
    document = UpdateSageOrderSyncDocument;
    client = 'sage';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SyncSageOrderSyncDocument = gql`
    mutation SyncSageOrderSync($number: String!) {
  syncSageOrderSync(number: $number) {
    ...SageOrderSyncDetail
  }
}
    ${SageOrderSyncDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SyncSageOrderSyncGQL extends Apollo.Mutation<SyncSageOrderSyncMutation, SyncSageOrderSyncMutationVariables> {
    document = SyncSageOrderSyncDocument;
    client = 'sage';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteSageOrderSyncDocument = gql`
    mutation DeleteSageOrderSync($number: String!) {
  deleteSageOrderSync(number: $number) {
    ...SageOrderSyncDetail
  }
}
    ${SageOrderSyncDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteSageOrderSyncGQL extends Apollo.Mutation<DeleteSageOrderSyncMutation, DeleteSageOrderSyncMutationVariables> {
    document = DeleteSageOrderSyncDocument;
    client = 'sage';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }