import { Injectable } from '@angular/core';
import {
  IDataCollection,
  IDataCollectionProvider,
  IDataProvider,
  IDataQuery,
} from '@x/common/data';
import { SageOrderSyncFilterInput } from '@x/schemas/sage';
import { Observable } from 'rxjs';
import { ISageOrderSyncDetail, ISageOrderSyncRow } from '../sage-types';
import { SageOrderSyncClient } from '../services/sage-order-sync.client';
import { SageOrderSyncRowFragment } from '../services/sage-order-sync.gql.generated';

@Injectable({ providedIn: 'root' })
export class SageOrderSyncDetailProvider implements IDataProvider<ISageOrderSyncDetail, string> {
  constructor(private client: SageOrderSyncClient) {}

  fetchSingle(id: string): ISageOrderSyncDetail | Observable<ISageOrderSyncDetail> {
    return this.client.fetchDetail(id);
  }

  toId(model: ISageOrderSyncDetail): string {
    return model.number;
  }

  toString(model: ISageOrderSyncDetail): string {
    return `${model.number}`;
  }
}

@Injectable({ providedIn: 'root' })
export class SageOrderSyncRowCollectionProvider
  implements
    IDataProvider<ISageOrderSyncRow, string>,
    IDataCollectionProvider<ISageOrderSyncRow, SageOrderSyncFilterInput, any, string>
{
  constructor(private client: SageOrderSyncClient) {}

  fetchSingle(id: string): ISageOrderSyncRow | Observable<ISageOrderSyncRow> {
    return this.client.fetchDetail(id);
  }

  fetchCollection(
    query: Readonly<IDataQuery<SageOrderSyncFilterInput, any>>,
  ):
    | IDataCollection<SageOrderSyncRowFragment>
    | Observable<IDataCollection<SageOrderSyncRowFragment>> {
    return this.client.fetchRows({
      filter: {
        ...query.filter,
        search: query.searchText,
      },
      page: query.page,
      sort: query.sort,
    });
  }

  toId(model: ISageOrderSyncRow): string {
    return model.number;
  }

  toString(model: ISageOrderSyncRow): string {
    return `${model.number}`;
  }
}
