import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { GRAPH_CLIENT } from '@x/common/graph';
import { LocaleModule } from '@x/common/locale';
import { SageAuthTokenInterceptor } from './auth/auth-token.interceptor';
import { SageAuthTokenContextService } from './auth/sage-auth-token-context.service';
import { SageItemSyncDetailProvider } from './providers/sage-item-sync.data-provider';
import { SageOrderSyncDetailProvider } from './providers/sage-order-sync.data-provider';
import { SAGE_LANGUAGE } from './sage-language';
import { SageItemSyncClient } from './services/sage-item-sync.client';
import { SageOrderSyncClient } from './services/sage-order-sync.client';

export interface SageClientModuleConfig {
  graphUrl: string;
}

@NgModule({
  imports: [LocaleModule.forChild(SAGE_LANGUAGE)],
})
export class SageClientModule {
  static forRoot(config: SageClientModuleConfig): ModuleWithProviders<SageClientModule> {
    return {
      ngModule: SageClientModule,
      providers: [
        SageItemSyncClient,
        SageItemSyncDetailProvider,
        SageOrderSyncDetailProvider,
        SageOrderSyncClient,
        SageAuthTokenContextService,
        {
          provide: GRAPH_CLIENT,
          useValue: {
            name: 'sage',
            uri: config.graphUrl,
            defaultOptions: {
              query: { fetchPolicy: 'no-cache', errorPolicy: 'none', returnPartialData: false },
            },
          },
          multi: true,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: SageAuthTokenInterceptor,
          multi: true,
        },
      ],
    };
  }
}
