import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SageAuthTokenContextService } from './sage-auth-token-context.service';

@Injectable()
export class SageAuthTokenInterceptor implements HttpInterceptor {
  constructor(private authTokenContext: SageAuthTokenContextService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.authTokenContext.token;

    if (token !== null && req.url === '/sage/graphql') {
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
    }

    return next.handle(req);
  }
}
