import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SageAuthTokenContextService {
  private _token: string | null = null;

  get token(): string | null {
    return this._token;
  }

  public setToken(token: string) {
    this._token = token;
  }

  public clearToken() {
    this._token = null;
  }
}
