import { Injectable } from '@angular/core';
import { mapFetchResultData } from '@x/common/graph';
import { UpdateSageStockItemSyncInput } from '@x/schemas/sage';
import { map, Observable } from 'rxjs';
import {
  SageItemSyncDetailFragment,
  SageItemSyncGQL,
  UpdateSageItemSyncGQL,
} from './sage-item-sync.gql.generated';

@Injectable()
export class SageItemSyncClient {
  constructor(
    private readonly SageItemSyncGQL: SageItemSyncGQL,
    private readonly UpdateSageItemSyncGQL: UpdateSageItemSyncGQL,
  ) {}

  fetchDetail(sku: string): Observable<SageItemSyncDetailFragment> {
    return this.SageItemSyncGQL.fetch({ sku }).pipe(map((r) => r.data.sageItemSync));
  }

  update(input: UpdateSageStockItemSyncInput) {
    return this.UpdateSageItemSyncGQL.mutate({ input }).pipe(
      mapFetchResultData((r) => r.updateSageItemSync),
    );
  }
}
